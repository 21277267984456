<template>
  <div class="wrap-search-bar">
    <TopNav
      v-if="
        permissionV2.lprEventAccess > 0 &&
        permissionV2.lprEventAdvancedAccess > 0
      "
      :navList="permissionNavList"
      :isSubNav="true"
      v-model="searchTab"
      @goTo="selectTab"
    />
    <div key="history" v-if="searchTab === 0" class="search-bar">
      <div class="search-bar-left">
        <div class="action-cause">
          <div class="title">{{ $t('search_cause') }}<span class="required"> *</span></div>
          <SelectInput v-model.trim="queryCause" :options="getInputOptions(queryCauses)" :placeholder="$t('search_cause_placehold')" />
        </div>
        <div class="divider"></div>
        <div class="plate-device">
          <div class="query-type">
            <RadioSelect v-model="queryType" :options="queryTypeOptions" />
          </div>
          <div v-if="queryType === 'plate'" class="plate">
            <div class="title">{{ $t('search_plate_number_required') }}<span class="required"> *</span></div>
            <SelectInput v-model.trim="licensePlate" :options="getInputOptions(queryNumber)" @input="licensePlate = licensePlate.toUpperCase()" :placeholder="$t('license_plate_example')" />
          </div>
          <div v-else class="query-device">
            <div class="title">{{ $t('search_device_required') }}<span class="required"> *</span></div>
            <TreeSelect 
              class="device" 
              v-model="selectedDevice" 
              :data="deviceTreeList" 
              :groupList="groupList"  
              :isGroupSelect="false"
              :isMultipleSelect="false"
            />
          </div>
        </div>
        <DateRangeSelect 
          v-if="queryType === 'plate'" 
          class="dt-select" 
          v-model="basicDateRange.value" 
          :range="true"
          :title="$t('search_time_interval')"
          :maxRangeDays="basicDateRange.maxRangeDays"
          :enabledDateStart="basicDateRange.enabledDateStart" 
          :enabledDateEnd="basicDateRange.enabledDateEnd" 
        />
        <DateRangeSelect 
          v-else 
          class="dt-select" 
          v-model="deviceDateTime" 
          :range="false" 
          :interval="30"
          :title="$t('search_time_interval')"
          :enabledDateStart="basicDateRange.enabledDateStart" 
          :enabledDateEnd="basicDateRange.enabledDateEnd" 
        />
        <MagnifierSearchBtn 
          class="search-btn"
          :active="searchEventLoading"
          @click="searchHistoryEvents()" 
        />
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_clear')/*清除*/" placement="bottom">
          <div class="tool" @click="earseSearchParams">
            <img src="@/assets/icons/erase-white.svg" alt="">
          </div>
        </el-tooltip>
      </div>
      <div 
        class="export-btn" 
        :class="{ disabled: !isDataReady || !isReadyBasicExport }" 
        @click="basicExport">
        <img src="@/assets/icons/download.svg" alt="">
        {{ $t('search_export') }}
      </div>
    </div>
    <div key="advancedSearch" v-else-if="searchTab === 1" class="search-bar">
      <div class="search-bar-left">
        <div class="action-cause">
          <div class="title">{{ $t('search_cause') }}<span class="required"> *</span></div>
          <SelectInput v-model.trim="advQueryCause" :options="getInputOptions(queryCauses)" :placeholder="$t('search_cause_placehold')" />
        </div>
        <div class="divider"></div>
        <TreeSelect 
          class="device" 
          v-model="selectedDevice" 
          :title="$t('search_device')"
          :data="deviceTreeList" 
          :groupList="groupList"  
          :isGroupSelect="true"
          :isMultipleSelect="false"
        />
        <div v-if="!isNpa" class="plate">
          <div class="title">{{ $t('search_plate_number'/*車號*/) }}</div>
          <SelectInput v-model.trim="advLicensePlate" :options="getInputOptions(queryNumber)" @input="advLicensePlate = advLicensePlate.toUpperCase()" :placeholder="$t('license_plate_example')" />
        </div>
        <DateRangeSelect 
          class="dt-select" 
          v-model="advDateRange.value" 
          :range="true" 
          :title="$t('search_time_interval')"
          :maxRangeDays="advDateRange.maxRangeDays"
          :enabledDateStart="advDateRange.enabledDateStart" 
          :enabledDateEnd="advDateRange.enabledDateEnd" 
        />
        <MagnifierSearchBtn 
          class="search-btn"
          :active="searchEventLoading"
          @click="advancedSearch()" 
        />
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_clear')/*清除*/" placement="bottom">
          <div class="tool" @click="earseAdvSearchParams">
            <img src="@/assets/icons/erase-white.svg" alt="">
          </div>
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_adv_filter')/*進階條件*/" placement="bottom">
          <div class="tool" @click="showAdvSelect">
            <img src="@/assets/icons/filter.svg" alt="">
          </div>
        </el-tooltip>
      </div>
      <div 
        class="export-btn" 
        :class="{ disabled: !isDataReady || !isReadyAdvancedExport }"
        @click="advancedExport">
        <img src="@/assets/icons/download.svg" alt="">
        {{ $t('search_export') }}
      </div>
    </div>
    <div v-if="searchTab === 1 && showAdvancedSelect" class="advanced-search">
      <Select 
        v-for="item in advSelections"
        :key="item.kind"
        class="adv-item"
        :class="{ 'adv-item-wider': item.kind === 'tag' }"
        :title="item.title"
        :multiSelect="item.multiSelect" 
        v-model="advParam[item.kind]" 
        :options="getOptions(item.kind, item.options)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { carType, advancedSelectItems, advancedSelectList } from '@/config/history.js'
import { apiGetBoviaLprEventsReports } from '@/api/index.js'
import { genFormattedDatetime } from '@/utils/lib.js'
import { getLprBasicDateRangeSetting, getLprAdvDateRangeSetting } from '@/config/dateRange.js'

export default {
  name: 'SearchBarLpr', 
  components: {
    TopNav: () => import('@/components/tools/TopNav.vue'),
    Select: () => import('@/components/History/SearchBar/Select.vue'),
    SelectInput: () => import('@/components/History/SearchBar/SelectInput.vue'), 
    RadioSelect: () => import('@/components/History/SearchBar/RadioSelect.vue'),
    DateRangeSelect: () => import('@/components/Base/DateRangeSelect.vue'),
    TreeSelect: () => import('@/components/Base/TreeSelect.vue'),
    MagnifierSearchBtn: () => import('@/components/Base/MagnifierSearchBtn.vue'),
  },
  data() {
    return {
      maxExportCount: 100000, // 最多可匯出的檔案數量
      carType,
      advancedSelectItems,
      advancedSelectList,
      searchTab: 0,
      queryCause: '', // 查詢案由
      licensePlate: '', // 車號
      historyDateTime: [], // 歷史事件時間區間
      deviceDateTime: null, // 設備事件時間區間
      enabledDateStart: null, // 一般查詢可以查詢開始時間
      enabledDateEnd: null, // 一般查詢可以查詢結束時間
      queryType: 'plate', // 查詢類型: plate: 車號, device: 設備
      queryTypeOptions: [
        { label: 'search_plate_number' /*車號*/, value: 'plate' },
        { label: 'search_device' /*設備*/, value: 'device' },
      ],
      selectedDevice: null, // 設備
      basicDateRange: {
        value: [],
        maxRangeDays: 0,
        enabledDateStart: null,
        enabledDateEnd: null,
      },
      
      /** 進階搜尋 **/
      advQueryCause: '', // 進階搜尋案由
      advLicensePlate: '', // 進階搜尋車號
      // advCarType: null, // 車種 null: 全部 , 3: 汽車 , 4: 機車
      advDateTime: [], // 進階搜尋時間區間
      advDateRange: {
        value: [],
        maxRangeDays: 0,
        enabledDateStart: null,
        enabledDateEnd: null,
      },
      advParam: {
        eventCategory: 'all',
        channelId: 'all',
        missionCodes: [1, 2, 3],
        missionStatus: [1, 2, 3], // 全部 
        tag: [],
        executedStatus: [1, 2, 3], // 全部 
        chasedStatus: [3] // 完成
      },
      showAdvancedSelect: true,
    }
  },
  computed: {
    ...mapState(['permissionV2', 'tagList', 'customize']),
    ...mapState('history', [
      'queryCauses',
      'queryNumber',
      'eventPlate', 
      'historyTotal',
      'historyEventList', 
      'historyPageSize', 
      'searchEventLoading',
      'exportList',
      'isReadyBasicExport',
      'isReadyAdvancedExport',
      'getEventsParams',
    ]),
    ...mapState('account', ['deviceTreeList', 'groupList']),
    advSelections() {
      return this.advancedSelectList.filter(item => this.advancedSelectItems[this.advParam.eventCategory].includes(item.kind))
    },
    permissionNavList() {
      const list = []

      if (this.permissionV2.lprEventAccess > 0) {
        list.push({
          navTitle: 'search_tab_history'/*一般查詢*/,
          comp: 0,
        })
      }

      if (this.permissionV2.lprEventAdvancedAccess > 0) {
        list.push({
          navTitle: 'search_tab_advanced_search'/*進階查詢*/,
          comp: 1,
        })
      }
      return list
    },
    isNpa() {
      return this.customize === 'npa'
    },
    isDataReady() {
      return this.historyEventList.length > 0 && this.historyTotal > 0
    },
  },
  watch: {
    searchTab() {
      if (this.searchTab === 0) this.showAdvancedSelect = false
      if (this.searchTab === 1) this.showAdvancedSelect = true
      this.earseSearchParams()
      this.earseAdvSearchParams()
      this.resetHistoryData() // 清空前一tab搜尋的事件

      this.getDeviceTreeData() // 取得設備樹狀資料
    },
    'permissionV2.lprEventAccess'() {
      if (this.permissionV2.lprEventAccess === 0) this.searchTab = -1
    },
    'advParam.eventCategory'() {
      this.advParam.channelId = 'all' // 全部
      this.advParam.missionCodes = [1, 2, 3] // 全部 
      this.advParam.missionStatus = [1, 2, 3] // 全部 
      this.advParam.tag = []
      this.advParam.executedStatus = [1, 2, 3] // 全部 
      this.advParam.chasedStatus = [3] // 完成
    },
  },
  mounted() {
    if (this.$route.query.maxExp) {
      this.maxExportCount = parseInt(this.$route.query.maxExp)
    }
    console.log('maxExportCount:', this.maxExportCount)
    
    // 預設搜尋時間區間，歷史事件預設3個月，進階搜尋預設1個月
    //  2022.10.27 一般查詢：今日 24:00，往前180 天，至多 366 天

    this.initBasicDateRange()
    this.initAdvDateRange()
    this.deviceDateTime = genFormattedDatetime(-30, 'minutes', 'minute') // 設置為當前時間，但是 0 秒鐘、0 毫秒
    
    this.getDeviceTreeData() // 取得設備樹狀資料
    
    // 若eventPlate不為空則以eventPlate帶入車號
    if (this.eventPlate) {
      this.licensePlate = this.eventPlate
    }

    // 接收來自其他元件的事件以及參數
    this.$bus.$on('queryByEvent', (params) => {
      this.queryByEvent(params)
    })
  },
  beforeDestroy() {
    this.$bus.$off('queryByEvent')
  },
  methods: {
    ...mapMutations('history', [
      'updateSearchMode',
      'updateBeforeAfterId',
      'updateGetEventsParams',
      'updateHistoryEventIndex',
      'updateFocusEventIndex',
      'updateHistoryCurrentPage',
      'updateHistoryTotal',
      'updateExportList',
      'updateShowExportInfoModal',
      'updateIsReadyBasicExport',
      'updateIsReadyAdvancedExport',
    ]),
    ...mapActions('history', [
      'searchEvents', 
      'resetHistoryData'
    ]),
    ...mapActions('account', ['getDeviceTreeList']),
    initBasicDateRange() {
      // 車牌一般預設搜尋區間: 預設六個月
      // 車牌一般最大可搜尋區間: 預設最近六個月
      // 車牌一般最多可搜尋的範圍: 預設最近一年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } 
        = getLprBasicDateRangeSetting()
      this.basicDateRange.value = defaultDateRange
      this.basicDateRange.maxRangeDays = maxRangeDays
      this.basicDateRange.enabledDateStart = enabledDateStart
      this.basicDateRange.enabledDateEnd = enabledDateEnd
    },
    initAdvDateRange() {
      // 車牌進階預設搜尋區間: 預設一週
      // 車牌進階最大可搜尋區間: 預設最近六個月
      // 車牌進階最多可搜尋的範圍: 預設最近一年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } 
        = getLprAdvDateRangeSetting()
      this.advDateRange.value = defaultDateRange
      this.advDateRange.maxRangeDays = maxRangeDays
      this.advDateRange.enabledDateStart = enabledDateStart
      this.advDateRange.enabledDateEnd = enabledDateEnd
    },
    getDeviceTreeData() {
      const payload = this.searchTab === 0 ? { public: 1 } : { public: 0 }
      this.getDeviceTreeList(payload)
    },
    getInputOptions(list) {
      return list.map(item => {
        return {
          label: item,
          value: item
        }
      })
    },
    selectTab(index) {
      // 2023.05.30 進階搜尋: permissionV2.lprEventAdvancedAccess > 0
      // 一般搜尋: permissionV2.lprEventAccess > 0 可以進  
      if (this.permissionV2.lprEventAccess === 0) return
      if (index === 1 && this.permissionV2.lprEventAdvancedAccess === 0) return // 想切換到進階搜尋，但只有一般搜尋的權限
      this.searchTab = index
    },
    showAdvSelect() {
      this.showAdvancedSelect = !this.showAdvancedSelect
    },
    getOptions(kind, options) {
      // 若是tag則須取得vuex tagList
      if (kind === 'tag') {
        const tagOptions = this.tagList.map(tag => {
          return { label: tag.name, value: tag.id, locked: tag.locked }
        })
        return [...options, ...tagOptions]
      } else return options
    },
    handleLicensePlate(vlp) {
      // 將全形逗點轉換成半形逗點
      vlp = vlp.replace(/，/g, ',')

      // 去除半形逗點前後的空格
      vlp = vlp.replace(/\s*,\s*/g, ',')

      // 去除開頭結尾的空格，並將中間的空格轉換成逗點
      vlp = vlp.trim().replace(/\s+/g, ',')

      return vlp
    },
    earseSearchParams() {
      this.selectedDevice = null
      this.queryCause = ''
      this.licensePlate = ''
      this.queryType = 'plate'
      this.initBasicDateRange()
    },
    earseAdvSearchParams() {
      this.selectedDevice = null
      this.advQueryCause = ''
      this.advLicensePlate = ''
      // this.advCarType = null
      this.advParam.eventCategory = 'all'
      this.advParam.channelId = 'all' // 全部
      this.advParam.missionCode = [1, 2, 3] // 全部 
      this.advParam.missionStatus = [1, 2, 3] // 全部 
      this.advParam.tag = []
      this.advParam.executedStatus = [1, 2, 3] // 全部 
      this.advParam.chasedStatus = [3] // 完成
      this.initAdvDateRange()
    },
    checkParams() {
      if (this.queryCause.length === 0 || this.queryCause.length > 30) {
        let msg = this.queryCause.length === 0 ? this.$t('search_hint_reason') : this.$t('search_hint_reason_length')
        this.$notify({ title: this.$t('search_hint'), message: msg, type: 'warning' })
        return false
      }

      if (this.queryType === 'plate' && this.licensePlate.length === 0) {
        this.$notify({ title: this.$t('search_hint'), message: this.$t('search_hint_fill_number'), type: 'warning' })
        return false
      }
      
      if (this.queryType === 'device' && !this.selectedDevice.id) {
          this.$notify({ title: this.$t('search_hint'), message: this.$t('search_hint_fill_device'), type: 'warning' })
          return false
      }

      if (this.basicDateRange.value.length === 0 || !this.deviceDateTime) {
        this.$notify({ title: this.$t('search_hint'), message: this.$t('search_hint_fill_time'), type: 'warning' })
        return false
      }
      return true
    },
    async searchHistoryEvents() {
      if (!this.checkParams() || this.searchEventLoading) return
      
      this.updateSearchMode('basic') // 設定搜尋模式為一般搜尋
      this.updateBeforeAfterId(null) // reset beforeAfterId
      let param = {}
      param.purpose = this.queryCause // 查詢案由
      param.limit = this.historyPageSize
      if (this.queryType === 'plate') {
        param.content = this.handleLicensePlate(this.licensePlate)  // 車號
        if (this.basicDateRange.value.length == 2) {  // 時間區間
          param.afterDetectTime = new Date(this.basicDateRange.value[0]).toISOString()
          param.beforeDetectTime = new Date(this.basicDateRange.value[1]).toISOString()
        }
      } else if (this.queryType === 'device') {
        param.user = this.selectedDevice.id // 設備
        param.afterDetectTime = new Date(this.deviceDateTime).toISOString()
        let afterDetectTime = new Date(this.deviceDateTime)
        afterDetectTime.setMinutes(afterDetectTime.getMinutes() + 30)
        param.beforeDetectTime = afterDetectTime.toISOString()
      }

      // 將搜尋參數存至vuex
      this.updateGetEventsParams(param)
      await this.searchEvents({ page: 1 })
      this.updateIsReadyBasicExport(true)

      // 查無資料時跳出提示
      if (this.historyEventList.length === 0) {
        this.$notify({
          title: this.$t('search_hint'),
          message: this.$t('search_hint_nodata'),
          type: 'warning'
        })
      }
    },
    checkQueryCause() {
      if (this.advQueryCause.length === 0 || this.advQueryCause.length > 30) {
        let msg = this.advQueryCause.length === 0 ? this.$t('search_hint_reason') : this.$t('search_hint_reason_length')
        this.$notify({ title: this.$t('search_hint'), message: msg, type: 'warning' })
        return false
      }
      return true
    },
    async advancedSearch() {
      if (!this.checkQueryCause() || this.searchEventLoading) return
      
      this.updateSearchMode('advanced') // 設定搜尋模式為進階搜尋
      this.updateBeforeAfterId(null) // reset beforeAfterId
      this.updateHistoryTotal(0) // 重新查詢時先將總數設為0(畫面顯示讀取中...)
      const param = {}
      param.purpose = this.advQueryCause // 查詢案由
      param.limit = this.historyPageSize
      // 設備
      if (this.selectedDevice?.id) {
        if (this.selectedDevice.isHasChild) param.group = [this.selectedDevice.groupId]
        else param.user = [this.selectedDevice.id]  
      }

      if (this.advLicensePlate) param.content = this.handleLicensePlate(this.advLicensePlate)  // 車號
      // if (this.advCarType) param.classification = this.advCarType  // 車種

      // 時間區間
      if (this.advDateRange.value.length == 2) {
        param.afterDetectTime = new Date(this.advDateRange.value[0]).toISOString()
        param.beforeDetectTime = new Date(this.advDateRange.value[1]).toISOString()
      }

      // 訂閱
      if (this.advParam.eventCategory === 'subscribed') {
        param.subscribed = 1
      }

      // 任務
      if (this.advParam.eventCategory === 'missioned') {
        // missioned 任務 [1 & 2](未完成) [3] (任務有完成) [1&2&3](全部 )
        param.missioned = this.advParam.missionStatus
        if (this.advParam.missionCodes) // 1:協尋, 2:盤查, 3:逮捕
          param.missionCodes = this.advParam.missionCodes
      }

      // 標記
      if (this.advParam.eventCategory === 'executed') {
        // executed  標記 [1 & 2](未完成) [3] (查緝有完成)[1&2&3](全部 )
        param.executed = this.advParam.executedStatus
      }

      // 車輛標記
      if (this.advParam.tag.length > 0 && this.advParam.tag.length !== this.tagList.length)
        param.tag = this.advParam.tag

      // 圍捕
      if (this.advParam.eventCategory === 'chased') {
        // chased  追車  [2]未完成 [3]完成 (追車不管種類可能是任務或標記)
        param.chased = this.advParam.chasedStatus
      } 

      // 誤判
      if (this.advParam.eventCategory === 'misjudged') {
        param.misjudged = 1
      } else {
        param.misjudged = 0
      }

      // 鏡頭
      if (this.advParam.channelId !== 'all') {
        param.channelId = this.advParam.channelId
      }

      // 將搜尋參數存至vuex
      this.updateGetEventsParams(param)
      await this.searchEvents({ page: 1 })
      this.updateIsReadyAdvancedExport(true)
    },
    async basicExport() {
      if (!this.isDataReady || !this.isReadyBasicExport) return
      // 車牌歷史事件若超過 maxExportCount 筆要不能匯出
      if (this.historyTotal > this.maxExportCount ) {
        this.$notify({
          title: this.$t('export_fail'),
          message: this.$t('export_fail_hint', {count: this.maxExportCount}),
          type: 'warning'
        })
        return
      }

      let res = await apiGetBoviaLprEventsReports()
      this.updateExportList(res.data)
      if (this.exportList.length >= 5) {
        this.updateShowExportInfoModal({ isShow: true, mode: 'warning' })
        return
      }
      // 開啟自訂檔名對話框
      this.updateShowExportInfoModal({ isShow: true, mode: 'filename' })
    },
    async advancedExport() {
      if (!this.isDataReady || !this.isReadyAdvancedExport) return
      // 車牌歷史事件若超過 maxExportCount 筆要不能匯出
      if (this.historyTotal > this.maxExportCount ) {
        this.$notify({
          title: this.$t('export_fail'),
          message: this.$t('export_fail_hint', {count: this.maxExportCount}),
          type: 'warning'
        })
        return
      }

      let res = await apiGetBoviaLprEventsReports()
      this.updateExportList(res.data)
      if (this.exportList.length >= 5) {
        this.updateShowExportInfoModal({ isShow: true, mode: 'warning' })
        return
      }
      // 開啟自訂檔名對話框
      this.updateShowExportInfoModal({ isShow: true, mode: 'filename' })
    },
    async queryByEvent(params) {
      this.queryType = 'device'
      this.selectedDevice = {
        id: params.userId,
        name: params.name,
        groupId: '',
        isHasChild: false,
      }

      // deep copy of this.getEventsParams
      const param = JSON.parse(JSON.stringify(this.getEventsParams))
      delete param.content 
      param.user = this.selectedDevice.id // 設備
      this.queryCause = param.purpose

      // 時間區間
      let afterDetectTime = new Date(params.detectTime)
      afterDetectTime.setMinutes(afterDetectTime.getMinutes() - 15)
      this.deviceDateTime = new Date(afterDetectTime)
      param.afterDetectTime = afterDetectTime.toISOString()
      afterDetectTime.setMinutes(afterDetectTime.getMinutes() + 30)
      param.beforeDetectTime = afterDetectTime.toISOString()

      
      this.updateBeforeAfterId(null) // reset beforeAfterId
      this.updateGetEventsParams(param) // 將搜尋參數存至vuex
      await this.searchEvents({ page: 1 })

      this.updateIsReadyBasicExport(true)
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

::placeholder {
  color: #1919194D;
  font-weight: 300;
}

.wrap-search-bar {
  width: 100%;
  height: 100%;
  background: #282942;
  position: relative;
}

.tabs {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  column-gap: 52px;
  background: #151B35;
  border-top: 1px solid #ffffff40;
  padding: 0px 40px;
  .tab {
    font-size: px2rem(24);
    font-weight: 300;
    line-height: 40px;
    color: #ffffff80;
    border-bottom: #151B35 5px solid;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    &.active {
      color: #FFC000;
      border-color: #FFC000;
    }
  }
}

.search-bar {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  padding: px2rem(12) px2rem(20);
  column-gap: px2rem(12);

  &-left {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: px2rem(12);
  }
}

.action-cause {
  width: 16%;
}

.divider {
  width: 1px;
  height: 100%;
  background: #ffffff80;
}

.title {
  @include font_style(px2rem(14), px2rem(21));
  margin-bottom: 4px;
}

.required {
  color: #FFC600;
}

input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
}

.plate-device {
  display: flex;
  column-gap: px2rem(12);
}

.query-type {
  min-width: 84px;
}

.plate {
  width: 170px;
}

.query-device {
  width: 320px;
  .device {
    width: 100%;
  }
}

.dt-select {
  width: 25%;
}

.search-btn {
  margin-top: 25px;
}

.tool {
  margin-top: 25px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #FFFFFF1A;
    border-radius: 8px;
  }
  &:active {
    background: #FFFFFF80;
  }
  img {
    width: 22px;
    height: 22px;
  }
}

.device {
  width: 22vw;
}

.export-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  height: px2rem(36);
  background: #4A5C78;
  padding: 0 px2rem(16);
  border-radius: px2rem(8);
  white-space: nowrap;
  @include font_style(px2rem(16), px2rem(24));
  user-select: none;
  cursor: pointer;

  img {
    width: px2rem(16);
    height: px2rem(16);
    margin-right: px2rem(8);
    vertical-align: baseline;
  }

  &:hover, &:active {
    background: #6E7D93;
  }

  &.disabled {
    background: #4A5C78;
    opacity: 0.2;
    cursor: default;
  }
}

.advanced-search {
  position: absolute;
  left: 12px;
  top: calc(100% + px2rem(8));
  background: #282942b3;
  border-radius: 3px;
  padding: 11px 12px 17px 12px;
  display: flex;
  z-index: 1;
}

.adv-item {
  width: 180px;
}

.adv-item:not(:last-child) {
  margin-right: 20px;
}

.adv-item:last-child {
  margin-left: 20px;
  position: relative;
}

.adv-item:last-child::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #ffffff;
}

.adv-item-wider {
  width: 310px;
}
</style>